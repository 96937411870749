import React from "react"
import bgImg from "@/shared/assets/bg_stars.png"

import { ReactComponent as BgLines } from "@/shared/assets/lines.svg"
import { ReactComponent as Planet } from "@/shared/assets/planetbg.svg"
import { ReactComponent as Galaxy } from "@/shared/assets/galaxy.svg"

import { BecomePart } from "@/widgets/BecomePart/BecomePart"
import { GetFun } from "@/widgets/GetFun/GetFun"
import { AmazingGraphics } from "@/widgets/AmazingGrapics/AmazingGraphics"
import { EasyAdministration } from "@/widgets/EasyAdministration/EasyAdministration"
import { Ending } from "@/widgets/Ending/Ending"
import { Header } from "@/widgets/Header/Header"
import useResize from "@/shared/lib/hooks/useResize"
import { MobileMain } from "@/Mobile/MobileMain/MobileMain"
import { Footer } from "@/widgets/Footer/Footer"

import cls from "./main.module.scss"
import {
    Outlet,
    useLocation,
    useNavigate,
} from "react-router-dom"

export const Main = () => {
    const windowWidth = useResize()
    const navigate = useNavigate()
    const location = useLocation()
    return windowWidth > 912 ? (
        <div className={cls.wrapper}>
            <main className={cls.Main}>
                <Header />
                {location.pathname === "/distributor" ? (
                    <Outlet />
                ) : (
                    <>
                        <BgLines className={cls.bgLines} />
                        <BecomePart />
                        <GetFun />
                        <div
                            onClick={() =>
                                navigate("/distributor")
                            }
                            className={cls.distributor}>
                            <p>
                                BECOME A<br />{" "}
                                <span>DISTRIBUTOR</span>
                            </p>
                        </div>
                        <AmazingGraphics
                            className={cls.AmazingGraphics}
                        />

                        <EasyAdministration />
                        <Ending />
                    </>
                )}
                <Footer />
            </main>
            {location.pathname !== "/distributor" && (
                <>
                    <Planet className={cls.planet} />
                    <Galaxy className={cls.galaxy} />
                    <Galaxy className={cls.galaxy_second} />
                    <img
                        src={bgImg}
                        className={cls.backgroundImg}
                    />
                </>
            )}
        </div>
    ) : (
        <MobileMain>
            <Outlet />
        </MobileMain>
    )
}
