import React from "react"
import { AdminSlides } from "@/widgets/Test/AdminSlides"
import { ReactComponent as FeatButton } from "@/Mobile/shared/assets/featButton.svg"
import { ReactComponent as BusinessGlow } from "@/Mobile/shared/assets/businessGlow.svg"
import cls from "./MobileBusiness.module.scss"
import { useNavigate } from "react-router-dom"

export const MobileBusiness = () => {
    const navigate = useNavigate()
    return (
        <div className={cls.MobileBusiness}>
            <BusinessGlow className={cls.BusinessGlow} />
            <div className={cls.headline}>
                <h1>Ready to</h1>
                <h2>Go business</h2>
                <p>Software included</p>
            </div>
            <AdminSlides className={cls.slides} />
            <p>
                <b>Incredibly </b> pleasant visual design,
                smooth animations and juicy colors will
                allow the player to immerse himself in a
                space journey that he has not yet seen
            </p>
            <p>
                Manage your gaming devices and view
                statistics
            </p>
            <FeatButton
                onClick={() =>
                    navigate("/business/features")
                }
                className={cls.FeatButton}
            />
        </div>
    )
}
