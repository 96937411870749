import React, { useState } from "react"
import startDistr from "@/Mobile/shared/assets/startDistrM.png"
import buildBussines from "@/Mobile/shared/assets/buildBus.png"
import { ReactComponent as WideArrow } from "@/Mobile/shared/assets/wideArrow.svg"
import { ReactComponent as StartGlow } from "@/Mobile/shared/assets/startGlow.svg"
import { ReactComponent as MidGlow } from "@/Mobile/shared/assets/middleGlow.svg"
import { ReactComponent as BottomGlow } from "@/Mobile/shared/assets/bottomGlow.svg"
import monetki from "@/Mobile/shared/assets/monetki.png"
import cls from "./MobileDistr.module.scss"
import { ContactForm } from "@/widgets/ContactForm/ContactForm"

export const MobileDistr = () => {
    const [open, setOpen] = useState(false)
    const HowItWorks = [
        {
            title: "1. Quick Sign-Up",
            description:
                "Just reach out to us to get started. We’re here to support anyone ready to join.",
        },
        {
            title: "2. Easy Onboarding",
            description:
                "Our team provides all the resources and guidance you’ll need. You’ll be set up and ready to connect with clients in no time.",
        },
        {
            title: "3. Install software",
            description:
                "You’re ready to attract clients and maximize your potential!",
        },
    ]
    return (
        <div className={cls.wrapper}>
            <div className={cls.MobileDistr}>
                <h1>START YOUR BUSINESS TODAY</h1>
                <div className={cls.becomeDistrContainer}>
                    <StartGlow className={cls.StartGlow} />
                    <img
                        src={startDistr}
                        alt='distr'
                    />
                    <button
                        onClick={() => setOpen(true)}
                        className={cls.becomeDistrButton}>
                        <h1>BECOME</h1>
                        <span>A Distributor</span>
                    </button>
                </div>
                <h1>We help you build</h1>
                <h2>a successful business</h2>
                <img
                    src={buildBussines}
                    alt='distr'
                />
                <div className={cls.list}>
                    <MidGlow className={cls.MidGlow} />
                    <div className={cls.listItem}>
                        <h3>Easy control</h3>
                        <p>
                            It is easy to manage, set up new
                            accounts and configure gaming
                            devices
                        </p>
                    </div>
                    <div className={cls.listItem}>
                        <h3>Manage your finances</h3>
                        <p>
                            Track the financial transactions
                            of all your gaming devices,
                            track profits and make payments
                        </p>
                    </div>
                    <div className={cls.listItem}>
                        <h3>Full support</h3>
                        <p>
                            A complete guide to use and a
                            fast support team ready to help
                            in any situation
                        </p>
                    </div>
                </div>
                <div className={cls.howItWorks}>
                    <BottomGlow
                        className={cls.BottomGlow}
                    />
                    <h1>How it works</h1>
                    <h2>Step by step</h2>
                    {HowItWorks.map((item) => {
                        return (
                            <div
                                className={
                                    cls.howxListItem
                                }>
                                <div
                                    className={
                                        cls.listItem
                                    }>
                                    <h3>{item.title}</h3>
                                    <p>
                                        {item.description}
                                    </p>
                                </div>
                                <WideArrow />
                            </div>
                        )
                    })}
                    <div className={cls.EarnMoney}>
                        <h4>EARN MONEY</h4>
                    </div>
                    <img
                        src={monetki}
                        className={cls.monetki}
                    />
                </div>
                <button
                    onClick={() => setOpen(true)}
                    className={cls.becomeDistrButton}>
                    <h1>BECOME</h1>
                    <span>A Distributor</span>
                </button>
            </div>
            <ContactForm
                open={open}
                setOpen={setOpen}
            />
        </div>
    )
}
