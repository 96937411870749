import React, { useState } from "react"
import startDistr from "@/shared/assets/startDistr.webp"
import buildBussines from "@/shared/assets/midBanner.webp"
import monetki from "@/shared/assets/monetkiPC.png"
import { ReactComponent as WideArrow } from "@/Mobile/shared/assets/wideArrow.svg"
import { ReactComponent as WideArrowRight } from "@/shared/assets/WideArrowRight.svg"
import { ReactComponent as StartGlow } from "@/shared/assets/startGlowPc.svg"
import { ReactComponent as MidGlow } from "@/shared/assets/midGlowPc.svg"
import { ReactComponent as BottomGlow } from "@/shared/assets/bottomGlowPc.svg"
import cls from "./BecomeDistributor.module.scss"
import { ContactForm } from "../ContactForm/ContactForm"

const BecomeButton = ({ setOpen }) => {
    return (
        <button
            onClick={() => setOpen(true)}
            className={cls.becomeDistrButton}>
            <h1>BECOME</h1>
            <span>A Distributor</span>
        </button>
    )
}

export const BecomeDistributor = () => {
    const [open, setOpen] = useState(false)
    const HowItWorks = [
        {
            title: "1. Quick Sign-Up",
            description:
                "Just reach out to us to get started. We’re here to support anyone ready to join.",
        },
        {
            title: "2. Easy Onboarding",
            description:
                "Our team provides all the resources and guidance you’ll need. You’ll be set up and ready to connect with clients in no time.",
        },
        {
            title: "3. Install software",
            description:
                "You’re ready to attract clients and maximize your potential!",
        },
    ]
    return (
        <div className={cls.distributor}>
            <div className={cls.startBus}>
                <StartGlow className={cls.StartGlow} />
                <h1>
                    START YOUR BUSINESS
                    <br />
                    TODAY
                </h1>
                <BecomeButton setOpen={setOpen} />
            </div>
            <img
                className={cls.startImg}
                src={startDistr}
                alt='Start your business'
            />
            <div className={cls.headlineBus}>
                <h1>We help you build</h1>
                <h2>a successful business</h2>
                <img
                    className={cls.buildBusImg}
                    src={buildBussines}
                    alt='distr'
                />
                <MidGlow className={cls.midGlow} />
            </div>
            <div className={cls.list}>
                <div className={cls.listItem}>
                    <h3>Easy control</h3>
                    <p>
                        It is easy to manage, set up new
                        accounts and configure gaming
                        devices
                    </p>
                </div>
                <div className={cls.listItem}>
                    <h3>Manage your finances</h3>
                    <p>
                        Track the financial transactions of
                        all your gaming devices, track
                        profits and make payments
                    </p>
                </div>
                <div className={cls.listItem}>
                    <h3>Full support</h3>
                    <p>
                        A complete guide to use and a fast
                        support team ready to help in any
                        situation
                    </p>
                </div>
            </div>
            <div className={cls.howItWorks}>
                <BottomGlow className={cls.BottomGlow} />
                <h1>How it works</h1>
                <h2>Step by step</h2>
                <div className={cls.howList}>
                    {HowItWorks.map((item, index) => {
                        return (
                            <>
                                <div
                                    className={
                                        cls.howxListItem
                                    }>
                                    <div
                                        className={
                                            cls.listItem
                                        }>
                                        <h3>
                                            {item.title}
                                        </h3>
                                        <p>
                                            {
                                                item.description
                                            }
                                        </p>
                                    </div>
                                </div>
                                {index !== 2 && (
                                    <WideArrowRight
                                        className={
                                            cls.ArrowRight
                                        }
                                    />
                                )}
                            </>
                        )
                    })}
                </div>
                <img
                    src={monetki}
                    className={cls.monetki}
                />
                <WideArrow className={cls.WideArrow} />

                <div className={cls.EarnMoney}>
                    <h4>EARN MONEY</h4>
                </div>

                <BecomeButton setOpen={setOpen} />
            </div>
            <ContactForm
                open={open}
                setOpen={setOpen}
            />
        </div>
    )
}
