import React from "react"
import { ReactComponent as Bag } from "@/Mobile/shared/assets/bag.svg"
import { ReactComponent as Rocket } from "@/Mobile/shared/assets/rocket-menu.svg"
import { ReactComponent as Gamepad } from "@/Mobile/shared/assets/gamepad.svg"
import { ReactComponent as Stars } from "@/Mobile/shared/assets/stars.svg"
import { ReactComponent as Coin } from "@/Mobile/shared/assets/Coin.svg"
import { ReactComponent as RocketButton } from "@/Mobile/shared/assets/contButton.svg"
import cls from "./MobileMenu.module.scss"
import { useLocation, useNavigate } from "react-router-dom"
import { classNames } from "@/shared/lib/utils/classNames"

export const MobileMenu = ({ open, setOpen }) => {
    const navigate = useNavigate()
    const location = useLocation()
    console.log(location)
    return (
        <div className={cls.MobileMenu}>
            <RocketButton
                onClick={() => setOpen(true)}
                className={classNames(
                    cls.RocketButton,
                    [],
                    {
                        [cls.hide]:
                            location.pathname ===
                            "/become_distributor",
                    }
                )}
            />
            <div
                onClick={() => navigate("/")}
                className={cls.navItem}>
                <Rocket
                    className={classNames(cls.svg, [], {
                        [cls.active]:
                            location.pathname === "/",
                    })}
                />
                <span>Home</span>
            </div>
            <div
                onClick={() => navigate("/about")}
                className={cls.navItem}>
                <Gamepad
                    className={classNames(cls.svg, [], {
                        [cls.active]:
                            location.pathname === "/about",
                    })}
                />
                <span>About</span>
            </div>
            <div
                onClick={() => navigate("/features")}
                className={cls.navItem}>
                <Stars
                    className={classNames(cls.svg, [], {
                        [cls.active]:
                            location.pathname ===
                            "/features",
                    })}
                />
                <span>Features</span>
            </div>
            <div
                onClick={() => navigate("/business")}
                className={cls.navItem}>
                <Bag
                    className={classNames(cls.svg, [], {
                        [cls.active]: location.pathname
                            .split("/")
                            .includes("business"),
                    })}
                />
                <span>Business</span>
            </div>
            <div
                onClick={() => navigate("/become_distributor")}
                className={classNames(cls.navItem, [
                    cls.Coin,
                ])}>
                <Coin
                    className={classNames(cls.svg, [], {
                        [cls.active]: location.pathname
                            .split("/")
                            .includes("become_distributor"),
                    })}
                />
                <span>Become a Distributor</span>
            </div>
        </div>
    )
}
