import React, { useEffect, useRef } from "react"
import logo from "@/shared/assets/logo_stellar_bet.webp"
import { ReactComponent as AboutGlow } from "@/Mobile/shared/assets/aboutGlow.svg"
import videoW from "@/shared/assets/videoW.webm"
import cls from "./MobileAbout.module.scss"

export const MobileAbout = () => {
    const videoRef = useRef()
    const videoContainer = useRef()
    const buttonRef = useRef()
    useEffect(() => {
        videoContainer.current.addEventListener("click", () => {
            buttonRef.current.classList.toggle(cls.active)
            videoRef.current.paused
                ? videoRef.current.play()
                : videoRef.current.pause()
        })
    })
    return (
        <div className={cls.MobileAbout}>
            <AboutGlow className={cls.AboutGlow} />
            <div className={cls.headline}>
                <h1>New and fresh</h1>
                <h2>look at a crash</h2>
                <p>game experience</p>
            </div>
            <p className={cls.someText}>
                We develop crash-style gambling games,
                delivering quality entertainment for over 30
                years.
            </p>
            <div ref={videoContainer} className={cls.video}>
                <div className={cls.videoControls}>
                    <svg
                        ref={buttonRef}
                        width='800px'
                        height='800px'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z'
                            fill='#1C274C'
                        />
                    </svg>
                </div>
                <video
                    ref={videoRef}
                    muted
                    playsInline
                    loop>
                    <source
                        src={videoW}
                        type='video/webm'
                    />
                    Sorry, your browser doesn't support
                    videos.
                </video>
            </div>
            <div className={cls.logoText}>
                <img
                    src={logo}
                    alt='Stellar Bets'
                />
                <p>
                    Engaging gameplay, high odds, and
                    instant results make Stellar Bets a
                    powerful tool to boost conversions and
                    revenue on your platform.
                </p>
            </div>
        </div>
    )
}
