import React, { useEffect, useState } from "react"
import bgImg from "@/shared/assets/bg_stars.png"
import logo from "@/shared/assets/logo_stellar_bet.webp"
import { ReactComponent as RocketButton } from "@/Mobile/shared/assets/contButton.svg"
import { MobileMenu } from "../widgets/MobileMenu/MobileMenu"
import cls from "./MobileMain.module.scss"
import { useLocation } from "react-router-dom"
import { ContactForm } from "@/widgets/ContactForm/ContactForm"

export const MobileMain = ({ children }) => {
    const [open, setOpen] = useState(false)
    const location = useLocation()
    useEffect(() => {
        const glowing = document.getElementById(
            "filter3_f_775_87728"
        )
        if (
            location.pathname.includes("business") ||
            location.pathname.includes("become_distibutor")
        ) {
            glowing.setAttribute("width", "0")
        } else {
            glowing.setAttribute("width", "606")
        }
    })
    return (
        <div className={cls.wrapper}>
            {!location.pathname
                .split("/")
                .includes("business") || !location.pathname === 'become_distributor' && (
                <img
                    src={bgImg}
                    className={cls.backgroundImg}
                />
            )}

            <div className={cls.MobileMain}>
                <img
                    src={logo}
                    className={cls.logo}
                />
                {children}
                <MobileMenu
                    open={open}
                    setOpen={setOpen}
                />
            </div>
            <ContactForm
                open={open}
                setOpen={setOpen}
            />
        </div>
    )
}
