import React from "react"
import getFun from "@/shared/assets/getFun.webp"
import logo from "@/shared/assets/logo_stellar_bet.webp"
import cls from "./getfun.module.scss"
export const GetFun = () => {
    return (
        <article className={cls.GetFun}>
            <svg
                className={cls.smallGlow}
                width='808'
                height='306'
                viewBox='0 0 808 306'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g filter='url(#filter0_f_94_18)'>
                    <ellipse
                        cx='404'
                        cy='153'
                        rx='290'
                        ry='39'
                        fill='#B700FF'
                    />
                </g>
                <defs>
                    <filter
                        id='filter0_f_94_18'
                        x='0'
                        y='0'
                        width='808'
                        height='306'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'>
                        <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                        />
                        <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='BackgroundImageFix'
                            result='shape'
                        />
                        <feGaussianBlur
                            stdDeviation='57'
                            result='effect1_foregroundBlur_94_18'
                        />
                    </filter>
                </defs>
            </svg>
            <div className={cls.topBlock}>
                <div className={cls.headline}>
                    <h1>New </h1>
                    <h2>and fresh look</h2>
                    <h3>
                        at a crash game{" "}
                        <span>experience</span>
                    </h3>
                </div>
                <div className={cls.miniBanner}>
                    <h2>30 years</h2>
                    <p>of market expertise</p>
                </div>
            </div>
            <p className={cls.centerText}>
                With over 30 years of experience in the
                betting industry, our projects are
                recognized and loved across many regions.
                We’ve channeled all our expertise into
                creating a truly unique new product.
            </p>
            <div className={cls.bottomBlock}>
                <img
                    className={cls.getFunImg}
                    src={getFun}
                />
                <div className={cls.description}>
                    <div className={cls.descText}>
                        <p>
                            Our game redefines the crash
                            genre with cutting-edge
                            technology, delivering stunning
                            graphics and an engaging user
                            experience. Designed to
                            captivate users, the game offers
                            an addictive experience with
                            seamless and intuitive UI. It
                            ensures high retention rates,
                            keeping players coming back for
                            more.{" "}
                        </p>
                        <p>
                            Our innovative approach
                            guarantees partners a
                            ready-made, proven-success
                            business opportunity.
                        </p>
                    </div>
                    <img
                        src={logo}
                        className={cls.logo}
                    />
                </div>
            </div>
            <svg
                className={cls.largeGlow}
                width='2055'
                height='1629'
                viewBox='0 0 2055 1629'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g filter='url(#filter0_f_93_9)'>
                    <path
                        d='M1728.21 814.199C1728.21 954.14 1764.11 1151.61 1632.68 1241.49C1507.89 1326.83 1220.67 1308.18 1033.91 1308.18C867.037 1308.18 525.322 1339.07 405.586 1269.28C250.177 1178.69 339.602 968.293 339.602 814.199C339.602 674.257 274.16 462.585 405.585 372.704C530.374 287.361 847.145 320.218 1033.91 320.218C1220.67 320.218 1530.05 287.361 1654.84 372.704C1786.27 462.585 1728.21 674.257 1728.21 814.199Z'
                        fill='url(#paint0_linear_93_9)'
                        fill-opacity='0.7'
                    />
                </g>
                <defs>
                    <filter
                        id='filter0_f_93_9'
                        x='0.422272'
                        y='0.422272'
                        width='2054.14'
                        height='1628.15'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'>
                        <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                        />
                        <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='BackgroundImageFix'
                            result='shape'
                        />
                        <feGaussianBlur
                            stdDeviation='156.789'
                            result='effect1_foregroundBlur_93_9'
                        />
                    </filter>
                    <linearGradient
                        id='paint0_linear_93_9'
                        x1='568.277'
                        y1='377.623'
                        x2='1732.12'
                        y2='1054.66'
                        gradientUnits='userSpaceOnUse'>
                        <stop stop-color='#FF0505' />
                        <stop
                            offset='1'
                            stop-color='#FFC04A'
                        />
                    </linearGradient>
                </defs>
            </svg>
        </article>
    )
}
