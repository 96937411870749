import React from "react"
import ReactDOM from "react-dom/client"
import "@/app/index.scss"
import App from "@/app/App"
import {
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom"
import { Main } from "@/page/Main/Main.jsx"
import { MobileHome } from "./Mobile/widgets/MobileHome/MobileHome"
import { MobileFeatures } from "./Mobile/widgets/MobileFeatures/MobileFeatures"
import { MobileAbout } from "./Mobile/widgets/MobileAbout/MobileAbout"
import { MobileBusiness } from "./Mobile/widgets/MobileBusiness/MobileBusiness"
import { MobileBusinessFeatures } from "./Mobile/widgets/MobileBusinessFeatures/MobileBusinessFeatures"
import { MobileDistr } from "./Mobile/widgets/MobileBecomeDistr/MobileDistr"
import { BecomeDistributor } from "./widgets/BecomeDistributor/BecomeDistributor"

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <Main />,
                children: [
                    {
                        path: "/",
                        element: <MobileHome />,
                    },
                    {
                        path: "/features",
                        element: <MobileFeatures />,
                    },
                    {
                        path: "/about",
                        element: <MobileAbout />,
                    },
                    {
                        path: "/business",
                        element: <MobileBusiness />,
                    },
                    {
                        path: "/business/features",
                        element: <MobileBusinessFeatures />,
                    },
                    {
                        path: "/become_distributor",
                        element: <MobileDistr />,
                    },
                    {
                        path: "/distributor",
                        element: <BecomeDistributor />,
                    },
                ],
            },
        ],
    },
])

const root = ReactDOM.createRoot(
    document.getElementById("root")
)
root.render(<RouterProvider router={router} />)
