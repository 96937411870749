import React from "react"
import getFun from "@/shared/assets/getFun.webp"
import { ReactComponent as HomeGlow } from "@/Mobile/shared/assets/homeGlow.svg"

import cls from "./MobileHome.module.scss"

export const MobileHome = () => {
    return (
        <div className={cls.MobileHome}>
            <HomeGlow className={cls.HomeGlow} />
            <div className={cls.headline}>
                <h1>become</h1>
                <h2>a part</h2>
                <h2>of stellar bets</h2>
            </div>
            <img
                src={getFun}
                className={cls.getFunImg}
            />
            <div className={cls.textBlock}>
                <p>
                    Bring fun and excitement in just a
                    couple of clicks.
                </p>
                <p>
                    Contact us or leave your contacts and we
                    will get back to you
                </p>
            </div>
        </div>
    )
}
