import React from "react"
import sixplayers from "@/shared/assets/sixplayer.png"
import oneclick from "@/shared/assets/onclick.png"
import gameplay from "@/shared/assets/gameplay.png"
import beagraphics from "@/shared/assets/beagraphics.png"
import { ReactComponent as FeaturesGlow } from "@/Mobile/shared/assets/featuresGlow.svg"
import { Carousel } from "@/shared/ui/Carousel/Carousel"
import { classNames } from "@/shared/lib/utils/classNames"
import cls from "./MobileFeatures.module.scss"

export const MobileFeatures = () => {
    return (
        <div className={cls.MobileAbout}>
            <FeaturesGlow className={cls.FeaturesGlow} />
            <div className={cls.headline}>
                <h1>Main</h1>
                <h2>Features</h2>
            </div>
            <Carousel
                className={cls.CarouselClass}
                width={"100vw"}
                withPagination={true}>
                <div
                    className={classNames(cls.gameplay, [
                        cls.sliderItem,
                    ])}>
                    <div className={cls.headline}>
                        <h1>ONE CLICK</h1>
                        <h2>INSTALLATION</h2>
                        <p>
                            Easy setup in just a few
                            clicks—simple enough for anyone
                            to get started
                        </p>
                    </div>
                    <img src={oneclick} />
                </div>
                <div
                    className={classNames(cls.oneclick, [
                        cls.sliderItem,
                    ])}>
                    <div className={cls.headline}>
                        <h1>BEAUTIFUL</h1>
                        <h2>GRAPHICS</h2>
                        <p>
                            A gripping experience that keeps
                            players engaged and coming back
                            for more, always hungry for the
                            next game
                        </p>
                    </div>
                    <img src={beagraphics} />
                </div>
                <div
                    className={classNames(cls.beagraphics, [
                        cls.sliderItem,
                    ])}>
                    <div className={cls.headline}>
                        <h1>EXCITING</h1>
                        <h2>GAMEPLAY</h2>
                        <p>
                            Addictive gameplay and
                            captivating mechanics will keep
                            players coming back
                        </p>
                    </div>
                    <img src={gameplay} />
                </div>
                <div
                    className={classNames(cls.sixplayers, [
                        cls.sliderItem,
                    ])}>
                    <div className={cls.headline}>
                        <h1>UP TO</h1>
                        <h2>6 PLAYERS</h2>
                        <p>
                            New killer feature allows to
                            engage up to 6 players at the
                            same time on the same screen
                        </p>
                    </div>
                    <img src={sixplayers} />
                </div>
            </Carousel>
        </div>
    )
}
