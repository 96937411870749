import React from "react"
import videoW from "../../shared/assets/videoW.webm"
import cls from "./amazing.module.scss"
import { classNames } from "@/shared/lib/utils/classNames"
import { ReactComponent as LineForShip } from "@/shared/assets/LineForShip.svg"
import { ReactComponent as Rocket } from "@/shared/assets/rocket.svg"
export const AmazingGraphics = ({ className }) => {
    return (
        <section
            id='features'
            className={classNames(cls.AmazingGraphics, [
                className,
            ])}>
            <svg
                className={cls.largeGlow}
                width='1976'
                height='1396'
                viewBox='0 0 1976 1396'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g filter='url(#filter0_f_93_12)'>
                    <ellipse
                        cx='988'
                        cy='698'
                        rx='738'
                        ry='448'
                        fill='url(#paint0_linear_93_12)'
                    />
                </g>
                <defs>
                    <filter
                        id='filter0_f_93_12'
                        x='0'
                        y='0'
                        width='1976'
                        height='1396'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'>
                        <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                        />
                        <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='BackgroundImageFix'
                            result='shape'
                        />
                        <feGaussianBlur
                            stdDeviation='125'
                            result='effect1_foregroundBlur_93_12'
                        />
                    </filter>
                    <linearGradient
                        id='paint0_linear_93_12'
                        x1='493.066'
                        y1='302.061'
                        x2='1623.37'
                        y2='1072.7'
                        gradientUnits='userSpaceOnUse'>
                        <stop stop-color='#5C4AFF' />
                        <stop
                            offset='1'
                            stop-color='#05ACFF'
                        />
                    </linearGradient>
                </defs>
            </svg>
            <div className={cls.headline}>
                <h1>AMAZING</h1>
                <h2>GRAPHICS</h2>
                <p>Video Shortcut</p>
            </div>
            <article className={cls.amazingFrame}>
                {/* <img
                    src={amazingPilot}
                    className={cls.Image}
                    alt='Amazing Space Adventure Frame'
                /> */}
                <video autoPlay muted loop>
                    <source
                        src={videoW}
                        type='video/webm'
                    />
                    Sorry, your browser doesn't support
                    videos.
                </video>
            </article>
            <p className={cls.bottomDescription}>
                Incredibly pleasant visual design, smooth
                animations and juicy colors will allow the
                player to immerse himself in a space journey
                that he has not yet seen
            </p>
            <LineForShip className={cls.LineForShip} />
            <Rocket className={cls.Rocket} />
        </section>
    )
}
