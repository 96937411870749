import React from "react"
import logo from "@/shared/assets/logo_stellar_bet.webp"

import cls from "./footer.module.scss"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { useLocation, useNavigate } from "react-router-dom"
export const Footer = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const navigationItem = (content, href, offset) => {
        if (location.pathname !== "/") {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/")}>
                    {content}
                </span>
            )
        } else {
            return (
                <AnchorLink
                    href={href}
                    offset={() => offset}>
                    {content}
                </AnchorLink>
            )
        }
    }
    return (
        <div className={cls.footerWrapper}>
            <footer className={cls.Footer}>
                <div className={cls.topblock}>
                    {navigationItem(
                        <img
                            src={logo}
                            className={cls.logo}
                        />,
                        "#home",
                        300
                    )}
                    <nav>
                        <p
                            onClick={() =>
                                navigate("/distributor")
                            }>
                            Become a distributor
                        </p>
                        {navigationItem(
                            <p>Home</p>,
                            "#home",
                            300
                        )}
                        {navigationItem(
                            <p>Features</p>,
                            "#features",
                            100
                        )}
                        {navigationItem(
                            <p>Contacts</p>,
                            "#contacts",
                            100
                        )}
                    </nav>
                </div>
                <div className={cls.copyright}>
                    <p>© 2024 Stellar Bets</p>
                    <p>All rights reserved.</p>
                </div>
            </footer>
        </div>
    )
}
