import React from "react"

import readyToGo from "@/shared/assets/readyToGo.png"
import lowSystem from "@/shared/assets/lowSystem.png"
import easyInstall from "@/shared/assets/easyInstall.png"
import { ReactComponent as ReadyGlow } from "@/shared/assets/readyGlow.svg"
import { ReactComponent as LowGlow } from "@/shared/assets/lowGlow.svg"
import { ReactComponent as EasyGlow } from "@/shared/assets/easyGlow.svg"
import { ReactComponent as SlideGlow } from "@/shared/assets/slideShowGlow.svg"
import { classNames } from "@/shared/lib/utils/classNames"
import { AdminSlides } from "../Test/AdminSlides"

import cls from "./administration.module.scss"

export const EasyAdministration = () => {

    return (
        <section className={cls.EasyAdministration}>
            <div className={cls.headline}>
                <h1>Easy</h1>
                <h2>Administration</h2>
                <p>Software included</p>
            </div>
            <article className={cls.sliderBox}>
                <AdminSlides />
                <SlideGlow className={cls.sliderGlow} />
            </article>
            <article className={cls.statistics}>
                <h3>
                    Manage your gaming devices and view
                    statistics
                </h3>
                <div className={cls.gridContainer}>
                    <div
                        className={classNames(
                            cls.readyToGo,
                            [cls.gridItem]
                        )}>
                        <div className={cls.head}>
                            <div className={cls.headText}>
                                <h1>READY </h1>
                                <h1>TO GO</h1>
                                <h3>business solutions</h3>
                            </div>
                            <img
                                src={readyToGo}
                                alt='Ready to Go'
                            />
                            <ReadyGlow
                                className={cls.readyGlow}
                            />
                        </div>
                        <p className={cls.description}>
                            Stellar Bets offers a ready-made
                            business solution that will help
                            you quickly integrate the game
                            into your platform. Our team has
                            developed all the necessary
                            tools and support to make the
                            startup process as simple and
                            efficient as possible.
                        </p>
                    </div>
                    <div
                        className={classNames(
                            cls.lowSystem,
                            [cls.gridItem]
                        )}>
                        <div className={cls.head}>
                            <div className={cls.headText}>
                                <h1>low</h1>
                                <h1>system</h1>
                                <h3>requirements</h3>
                            </div>
                            <LowGlow
                                className={cls.lowGlow}
                            />

                            <img
                                src={lowSystem}
                                alt='Low System'
                            />
                        </div>
                        <p className={cls.description}>
                            The extremely low system
                            requirements of the gaming
                            platform allows you to run on
                            the weakest devices and at the
                            same time enjoy stunning
                            graphics and engaging gameplay
                        </p>
                    </div>
                    <div
                        className={classNames(
                            cls.easyInstall,
                            [cls.gridItem]
                        )}>
                        <div className={cls.head}>
                            <div className={cls.headText}>
                                <h1>EASY</h1>
                                <h3>installation</h3>
                            </div>
                            <EasyGlow
                                className={cls.easyGlow}
                            />

                            <img
                                src={easyInstall}
                                alt='Easy Install'
                            />
                        </div>
                        <p className={cls.description}>
                            Integrating is fast and
                            resource-efficient. Our solution
                            allows for quick and seamless
                            connection to your platform,
                            with step-by-step instructions
                            and technical support ensuring a
                            smooth process.
                        </p>
                    </div>
                    <div
                        className={classNames(
                            cls.administrator,
                            [cls.gridItem]
                        )}>
                        <div className={cls.head}>
                            <div className={cls.headText}>
                                <h1>ADMINISTRATOR</h1>
                                <h3>
                                    and cashier interface
                                </h3>
                            </div>
                        </div>
                        <p className={cls.description}>
                            Our intuitive admin and cashier
                            interfaces make managing your
                            gaming operation a breeze—track
                            revenue, top up player accounts,
                            and monitor performance all from
                            any device, whether it’s
                            Telegram, a mobile app, or your
                            PC
                        </p>
                    </div>
                </div>
            </article>
        </section>
    )
}
